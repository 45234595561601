import { useGetConfiguration, useUpdateConfiguration } from '@frontend/api/configuration';
import { Page } from '@frontend/app/Page';
import { Pane } from '@frontend/components/Pane';
import { Configuration, DeliveryMethod } from '@frontend/shared/models/configuration';
import { Button, Col, Form, Input, Modal, Radio, Row, message } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';

export const ConfigurationPage: React.FC = () => {
  const { data } = useGetConfiguration();
  const { isLoading, mutateAsync: updateConfiguration } = useUpdateConfiguration();

  const [form] = Form.useForm<Configuration>();
  const deliveryMethod = Form.useWatch(['method'], form)

  const initialValues: Partial<Configuration> = useMemo(() => {
    if (data) {
      return {
        method: data.method,
        fax_number: data.fax_number,
        email: data.email,
      }
    } else {
      return {};
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleFormSubmit = useCallback(async (values) => {
    try {
      await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      return;
    }

    Modal.warning({
      title: 'Update Configuration',
      content: 'Are you sure you want to change the configuration?',
      okText: 'Update',
      
      onOk: async () => {
        try {
          await updateConfiguration(values);
          void message.success('Successfully updated the configuration.')
        } catch (error) {
          console.error(error);
          void message.error('There was an error saving the configuration.')
        }
      },
      okButtonProps: {
        loading: isLoading,
      },
    })
  }, [form, isLoading, updateConfiguration]);

  return (
    <Page title='Configuration'>
      <Pane>
        <Form<Configuration>
          form={form}
          name='configuration'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete='off'
          layout='horizontal'
          initialValues={initialValues}
          onFinish={handleFormSubmit}
        >
          <Row>
            <Col span={12}>
              <Form.Item label='Delivery Method' name='method' rules={[{
                required: true,
                message: 'Delivery Method is required',
              }]}>
                <Radio.Group buttonStyle='solid'>
                  <Radio.Button value={DeliveryMethod.NONE}>None</Radio.Button>
                  <Radio.Button value={DeliveryMethod.FAX}>Fax Number</Radio.Button>
                  <Radio.Button value={DeliveryMethod.EMAIL}>Email</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {
                deliveryMethod === DeliveryMethod.FAX && (
                  <Form.Item
                    label='Fax Number'
                    name='fax_number'
                    rules={[{
                      required: true,
                      message: 'Fax Number is required when the delivery method is Fax.'
                    }]}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              {
                deliveryMethod === DeliveryMethod.EMAIL && (
                  <Form.Item
                    label='Email'
                    name='email'
                    rules={[{
                      required: true,
                      message: 'Email is required when the delivery method is Email.',
                    }, {
                      message: 'Email is not valid.',
                      type: 'email',
                    }]}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              {
                <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    Save Configuration
                  </Button>
                </Form.Item>
              }
            </Col>
          </Row>
        </Form>
      </Pane>
    </Page>
  )
}