export enum DeliveryMethod {
  FAX = 'FAX',
  EMAIL = 'EMAIL',
  NONE = 'NONE',
}

export interface Configuration {
  method: DeliveryMethod;
  fax_number: string;
  email: string;
}