export enum Colors {
  white = '#FFFFFF',
  blue = '#6080E9',
  neutral = '#ABABAB',
  red = '#DC3312',
  yellow = '#FCE550',
  midnight = '#16385A',
  slate = '#3B5B75',
  grey = '#96B0D0',
  'light-grey' = '#EFF1F3',
  orange = '#CC5A22',
  green = '#50823C',
}