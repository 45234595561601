import { Patient, PatientStatus } from '@frontend/shared/models/patient';
import { Page } from '@frontend/app/Page';
import { Pane } from '@frontend/components/Pane';
import { Button, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link, generatePath } from 'react-router-dom';

import { PatientEnrollmentTable } from './PatientEnrollmentTable';
import { StatusFilterTabs } from './StatusFilterTabs';
import { ENROLL_PATH, PATIENTS_PATH, PATIENT_PATH } from '@frontend/app/paths';
import { useGetPatientsByStatus, useGetPatientsStatusCount } from '@frontend/api/patients';
import { useAuthContext } from '@frontend/auth/authContext';

const PAGE_SIZE = 10;

export const PatientEnrollmentQueuePage: React.FC = () => {
  const { accessToken: isLoggedIn } = useAuthContext();
  const [page, setPage] = React.useState(1);
  const [statusFilters, setStatusFilters] = React.useState<PatientStatus[]>([]);
  const {
    data: { results: patients = [], total = 0 } = {},
    error: loadPatientsError,
  } = useGetPatientsByStatus({
    skip: (page - 1) * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    statusFilters
  }, {
    queryConfig: {
      enabled: !!isLoggedIn,
    },
  });

  const { data: statusCounts, error: patientsCountError } = useGetPatientsStatusCount({
    queryConfig: {
      enabled: !!isLoggedIn,
    },
  });
  const navigate = useNavigate();

  if (loadPatientsError ?? patientsCountError) {
    void message.error('Failed to load patients');
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (patient: Patient) => {
    navigate(generatePath(`${PATIENTS_PATH}/${PATIENT_PATH}`, {
      patientId: patient.id,
    }));
  };

  return (
    <Page
      title="Enrollment Queue"
      pageActions={[
        <Link key="enroll_patient_link" to={`${PATIENTS_PATH}/${ENROLL_PATH}`}>
          <Button type="primary">Enroll patient</Button>
        </Link>,
      ]}
    >
      <StatusFilterTabs
        onStatusFiltersChanged={setStatusFilters}
        statusCounts={statusCounts ?? {}}
      />
      <Pane>
        <PatientEnrollmentTable
          patients={patients}
          total={total}
          page={page}
          pageSize={PAGE_SIZE}
          onPageChange={handlePageChange}
          onRowClick={handleRowClick}
        />
      </Pane>
    </Page>
  );
};
