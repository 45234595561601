import { Configuration } from "@frontend/shared/models/configuration";
import { IMutationExtraProps, IQueryExtraProps } from "./shared";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback } from "react";
import { apiAxios } from "./axios";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "./client";

const API_CONFIGURATION_PATH = '/configuration';

export const useGetConfiguration = (extra?: IQueryExtraProps<Configuration, AxiosError>) => {
  const { axiosConifg, queryConfig } = extra ?? {};

  const fetchConfiguration = useCallback(async () => {
    const { data } = await apiAxios.get<Configuration>(`${API_CONFIGURATION_PATH}`, axiosConifg);

    return data;
  }, [axiosConifg]);

  return useQuery<Configuration, AxiosError>('configuration', fetchConfiguration, queryConfig);
}

export const useUpdateConfiguration = (extra?: IMutationExtraProps<Configuration, AxiosError, Configuration>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};

  const updateConfiguration = useCallback(async (updateParams: Configuration) => {
    const { data } = await apiAxios.patch<Configuration, AxiosResponse<Configuration>, Configuration>(`${API_CONFIGURATION_PATH}`, updateParams);

    return data;
  }, [axiosConifg]);

  return useMutation<Configuration, AxiosError, Configuration>(updateConfiguration, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('configuration'),
      ]);

      if (mutationConfig?.onSuccess) await mutationConfig.onSuccess(...args);

      return args[0];
    }
  })
}