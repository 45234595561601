import { Layout, Menu, MenuProps, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { ReactNode, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Logo } from './Logo/Logo';
import { useAuthContext } from '@frontend/auth/authContext';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { UserRole } from '@frontend/shared/models/user';
import { CONFIGURATION_PATH, PATIENTS_PATH, USERS_PATH } from './paths';
import { Logout } from './Logout';
import { Login } from './Login';
import { Helmet } from 'react-helmet';

import styles from './Page.module.scss'

const { Header, Content, Footer } = Layout;

export interface IPageProps {
  title?: string;
  subTitle?: ReactNode;
  pageActions?: ReactNode;
  onBack?: () => void;
  children: ReactNode;
  backTo?: string;
}

export const Page: React.FC<IPageProps> = ({
  children,
  title,
  subTitle,
  onBack,
  pageActions: extra,
  backTo,
}) => {
  const { accessToken: isLoggedIn, user } = useAuthContext();
  const navigate = useNavigate();

  const handleOnBack =
    onBack !== undefined
      ? onBack
      : backTo !== undefined
      ? () => {
          navigate(backTo);
        }
      : undefined;
  
  const menuItems = useMemo<MenuProps['items']>(() => {
    const opts: ItemType[] = []

    if (isLoggedIn) {
      opts.push({
        key: 'patients',
        label:  <Link to={PATIENTS_PATH}>Patients</Link>,
      });

      if (user?.role === UserRole.ADMIN) {
        opts.push({
          key: 'users',
          label: <Link to={USERS_PATH}>Users</Link>,
        }, {
          key: 'configuration',
          label: <Link to={CONFIGURATION_PATH}>Configuration</Link>
        })
      }
    }


    return opts;
  }, [isLoggedIn, user]);
  
  const { pathname } = useLocation();

  const selectedKeys = useMemo(() => {
    if (pathname.startsWith(PATIENTS_PATH)) {
      return ['patients'];
    } else if (pathname.startsWith(USERS_PATH)) {
      return ['users'];
    } else if (pathname.startsWith(CONFIGURATION_PATH)) {
      return ['configuration'];
    }

    return [];
  }, [pathname]);

  return (
    <Layout className={styles.layout}>
      {title && (
        <Helmet>
          <title>Onc AI - {title}</title>
        </Helmet>
      )}
      <Header className={styles.header}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.menu}>
          {
            isLoggedIn && (
              <Menu
                theme='light'
                mode='horizontal'
                items={menuItems}
                defaultSelectedKeys={selectedKeys}
              />
            )
          }
        </div>
        {isLoggedIn && <Logout />}
        {!isLoggedIn && <Login />}
      </Header>
      <Content className={styles.content}>
        {title && (
          <PageHeader
            className={styles.pageHeader}
            onBack={handleOnBack}
            title={title}
            style={{ padding: 0 }}
            extra={extra}
            subTitle={subTitle}
          />
        )}
        {children}
      </Content>
      <Footer className={styles.footer} >
        <Typography.Text>
          {new Date().getFullYear()} &copy; Onc.AI All Rights Reserved
        </Typography.Text>
      </Footer>
    </Layout>
  );
};