import { Page } from '@frontend/app/Page';
import { Pane } from '@frontend/components/Pane';
import { Button, Form, Input, message } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, Location } from 'react-router-dom';

import { PATIENTS_PATH } from '@frontend/app/paths';
import { useAuthContext } from './authContext';

interface Credentials {
  user_name: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<Credentials>();
  const navigate = useNavigate();
  const location = useLocation();
  const { login, loading, accessToken } = useAuthContext();

  const redirectAfterLogin = useCallback(() => {
    let path: string = PATIENTS_PATH;
    const state = location.state as Location | undefined;

    if (state) {
      path = state.pathname;
      if (state.search != null && state.search !== '') {
        path = `${path}?${state.search}`;
      }
    }

    navigate(path);
  }, [location]);

  useEffect(() => {
    if (accessToken != null) {
      redirectAfterLogin();
    }
  }, [accessToken]);

  const handleSignInClicked = useCallback(async () => {
    let credentials: Credentials;
    try {
      credentials = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      return;
    }
    
    try {
      await login({
        userName: credentials.user_name,
        password: credentials.password,
        cb: () => {
          void message.success('Successfully logged in');
          redirectAfterLogin();
        },
      });
    } catch (error) {
      console.error(error);
      void message.error('Login failed');
    }
  }, [redirectAfterLogin, form, login]);

  return (
    <Page>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Pane style={{ width: '500px', margin: 'auto' }}>
        <Form
          form={form}
          name="basic"
          autoComplete="off"
          layout="vertical"
          initialValues={{ role: 'staff' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
          onFinish={() => {
            void handleSignInClicked();
          }}
        >
          <Form.Item
            label="Username"
            name="user_name"
            rules={[{ required: true, message: 'Username is required' }]}
          >
            <Input disabled={loading} autoFocus />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Password is required' }]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Sign In
          </Button>
        </Form>
      </Pane>
    </Page>
  );
};
