import { Colors } from '@frontend/app/GlobalStyles';
import { DatePicker, useTypableDatePickerInput } from '@frontend/components/DatePicker';
import { PatientHistology, PatientSex } from '@frontend/shared/models/patient';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { Rule } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import React, { useCallback } from 'react';

const disableFutureDates = (current) => {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
};

export interface IPatientFormFields {
  name: string;
  sex: PatientSex;
  date_of_birth: Moment;
  mrn: string;
  radiology_id?: string;
  pdl1_tps_pct?: number;
  nlr?: number;
  nlr_test_date?: Moment;
  histology?: PatientHistology;
  physician_name: string;
}

interface IPatientFormProps {
  isLoading?: boolean;
  form: FormInstance<IPatientFormFields>;
  handleFormSubmit: () => void;
  handleGoBack?: () => void;
  initialValues?: Partial<IPatientFormFields>;
}

export const PatientForm: React.FC<IPatientFormProps> = ({
  form,
  handleFormSubmit,
  handleGoBack,
  initialValues,
  isLoading = false,
}) => {
  const requireFieldRule: Rule = {
    required: true,
    message: 'Field is required',
  };

  const [nlrValue, setNlrValue] = React.useState<number>();

  React.useEffect(() => {
    let values = initialValues;
    if (values == null) values = {};
    values.nlr_test_date ??= moment();
    form.setFieldsValue(values);

    setNlrValue(values.nlr);
  }, [initialValues]);

  const onValuesChange = useCallback((changedValues, values: IPatientFormFields) => {
    setNlrValue(values.nlr);
  }, []);

  const inputRender = useTypableDatePickerInput<IPatientFormFields>({
    form,
  });

  return (
    <Form<IPatientFormFields>
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      layout="horizontal"
      initialValues={initialValues}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          form.submit();
        }
      }}
      onValuesChange={onValuesChange}
      onFinish={handleFormSubmit}
    >
      <Row>
        <Col span={12}>
          <fieldset>
            <Typography.Title level={5}>Physician Information</Typography.Title>
            <Form.Item label="Ordering Physician" name="physician_name" rules={[requireFieldRule]}>
              <Input disabled={isLoading} />
            </Form.Item>
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <fieldset>
            <Typography.Title level={5}>Patient Information</Typography.Title>
            <Form.Item label="Name" name="name" rules={[requireFieldRule]} tooltip='Lastname, Firstname'>
              <Input disabled={isLoading} placeholder='Lastname, Firstname' />
            </Form.Item>
            <Form.Item
              label="Date of birth"
              name="date_of_birth"
              rules={[requireFieldRule]}
              tooltip="Format: YYYY-MM-DD"
            >
              <DatePicker placeholder='YYYY-MM-DD' disabledDate={disableFutureDates} disabled={isLoading} inputRender={(datePickerProps) => inputRender(datePickerProps, 'date_of_birth')} />
            </Form.Item>
            <Form.Item
              label="Sex"
              name="sex"
              tooltip="Sex assigned at birth"
              rules={[requireFieldRule]}
            >
              <Select>
                {Object.values(PatientSex).map((sex) => (
                  <Select.Option key={sex} value={sex}>
                    {sex}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="MRN"
              name="mrn"
              tooltip="Medical record number."
              rules={[requireFieldRule]}
            >
              <Input disabled={isLoading} />
            </Form.Item>
            <Form.Item label="Radiology Id" name="radiology_id" tooltip="If different from MRN">
              <Input disabled={isLoading} autoFocus />
            </Form.Item>
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <fieldset>
            <Typography.Title level={5}>Lab data</Typography.Title>
            <Form.Item
              label="Histology"
              name="histology"
              rules={[requireFieldRule]}
            >
              <Select>
                <Select.Option value={PatientHistology.ADENOCARCINOMA}>
                  {PatientHistology.ADENOCARCINOMA}
                </Select.Option>
                <Select.Option value={PatientHistology.SQUAMOUS}>
                  {PatientHistology.SQUAMOUS}
                </Select.Option>
                <Select.Option value={PatientHistology.MIXED_OR_OTHER}>
                  MIXED / OTHER
                </Select.Option>
                <Select.Option value={PatientHistology.UNKNOWN_HISTOLOGY}>
                  UNKNOWN
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="NLR"
              name="nlr"
              rules={[{
                type: 'number',
                max: 99.9,
                min: 0,
                message: 'Value must be between 0 and 99.9'
              }]}
            >
              <InputNumber step={0.1} precision={1} disabled={isLoading} />
            </Form.Item>
            <Form.Item
              label="NLR Test Date"
              name="nlr_test_date"
              rules={[{
                required: nlrValue != null,
                message: 'Field is required',
              }]}
              tooltip="Format: YYYY-MM-DD"
            >
              <DatePicker allowClear={false} placeholder='YYYY-MM-DD' disabledDate={disableFutureDates} disabled={isLoading || nlrValue == null} inputRender={(datePickerProps) => inputRender(datePickerProps, 'nlr_test_date')}  />
            </Form.Item>
            <Form.Item
              label="PD-L1 TPS"
              name="pdl1_tps_pct"
              tooltip="PD-L1 expression as determined by the Tumor Proportion Score (TPS)."
              rules={[
                {
                  type: 'number',
                  max: 100,
                  min: 0,
                  message: 'Value must be between 0 and 100',
                },
              ]}
            >
              <InputNumber step={1} precision={0} disabled={isLoading} />
            </Form.Item>
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
            <Space>
              <Button
                style={{ width: '125px' }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Save Patient
              </Button>
              {handleGoBack && (
                <Button
                  onClick={() => {
                    handleGoBack();
                  }}
                >
                  Cancel
                </Button>
              )}
            </Space>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
            <Typography.Text>
              <span style={{ color: Colors.red }}>*</span> indicates a required field
            </Typography.Text>
          </Form.Item>
        </Col>
      </Row>

    </Form>
  );
};
