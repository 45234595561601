import { PatientStatus } from '@frontend/shared/models/patient';
import { StatusCounts } from '@frontend/shared/types';
import { Tabs } from 'antd';
import sum from 'lodash/sum';
import React from 'react';

interface Props {
  onStatusFiltersChanged: (statusFilters: PatientStatus[]) => void;
  statusCounts: StatusCounts;
}

export const StatusFilterTabs: React.FC<Props> = ({
  onStatusFiltersChanged,
  statusCounts,
}) => {
  const statusFilterTabItems = createStatusFilterTabItems(statusCounts);

  const handleTabChanged = (tabKey: string) => {
    const statusTab = StatusTabs.find((tab) => tab.key === tabKey);
    if (statusTab) {
      onStatusFiltersChanged(statusTab.statuses);
    }
  };

  return (
    <Tabs
      onChange={handleTabChanged}
      items={statusFilterTabItems}
      tabIndex={2}
    />
  );
};

export enum StatusFilterTabsKeys {
  ALL = 'ALL',
  IMAGING = 'IMAGING',
  ANNOTATE = 'ANNOTATE',
  PREDICTION = 'PREDICTION',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

const StatusTabs = [
  {
    label: 'All',
    key: StatusFilterTabsKeys.ALL,
    statuses: [],
  },
  {
    label: 'Imaging',
    key: StatusFilterTabsKeys.IMAGING,
    statuses: [
      PatientStatus.IMAGING_PENDING,
      PatientStatus.IMAGING_RECEIVED,
      PatientStatus.IMAGING_QC_FAIL,
    ],
  },
  {
    label: 'Annotate',
    key: StatusFilterTabsKeys.ANNOTATE,
    statuses: [PatientStatus.IMAGING_QC_PASS],
  },
  {
    label: 'Prediction',
    key: StatusFilterTabsKeys.PREDICTION,
    statuses: [PatientStatus.ANNOTATION_DONE],
  },
  {
    label: 'Complete',
    key: StatusFilterTabsKeys.COMPLETE,
    statuses: [PatientStatus.PREDICTION_DONE],
  },
  {
    label: 'Failed',
    key: StatusFilterTabsKeys.FAILED,
    statuses: [PatientStatus.IMAGING_QC_FAIL],
  },
];

function createStatusFilterTabItems(statusCounts?: StatusCounts) {
  const tabItems = StatusTabs.map((statusTab) => ({
    key: statusTab.key,
    label: labelWithCount(
      statusTab.label,
      sumCounts(statusTab.statuses, statusCounts),
    ),
  }));
  return tabItems;
}

function sumCounts(statuses: PatientStatus[], statusCounts?: StatusCounts) {
  let counts;

  // if no counts are available, return 0
  if (statusCounts === undefined) {
    counts = [];
    // if no statuses are provided, assume ALL tab is selected
  } else if (statuses.length === 0) {
    counts = Object.values(statusCounts);
  } else {
    counts = statuses.map((status) => statusCounts[status] ?? 0);
  }

  return sum(counts);
}

function labelWithCount(label: string, count?: number) {
  if (count !== undefined && count > 0) {
    return `${label} (${count})`;
  }

  return label;
}
