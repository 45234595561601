import { PatientStatus } from '@frontend/shared/models/patient';
import { Modal, Select, message } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useUpdatePatient } from '@frontend/api/patients';

interface IEditPatientStatusModalProps {
  open: boolean;
  onClose: (patientUpdated: boolean) => void;
  patientStatus: PatientStatus;
  patientId: string;
}

export const EditPatientStatusModal: React.FC<IEditPatientStatusModalProps> = ({
  open,
  patientStatus,
  patientId,
  onClose,
}) => {
  const [status, setStatus] = useState(patientStatus);

  const { mutateAsync: updatePatient } = useUpdatePatient();

  const handleUpdateClicked = useCallback(async () => {
    try {
      await updatePatient({
        id: patientId,
        data: {
          status,
        }
      })
      void message.success('Status updated');
      onClose(true);
    } catch (error) {
      void message.error('Update failed');
    }
  }, [updatePatient, status]);

  const statusOptions = useMemo(() => Object.values(PatientStatus), []);

  return (
    <Modal
      open={open}
      title="Update patient status"
      okText="Update"
      cancelText="Cancel"
      onCancel={() => onClose(false)}
      onOk={handleUpdateClicked}
      okButtonProps={{ disabled: patientStatus === status }}
    >
      <Select value={status} onChange={setStatus} style={{ width: '100%' }}>
        {statusOptions.map((statusValue: PatientStatus) => (
          <Select.Option key={statusValue} value={statusValue}>
            {statusValue}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};
