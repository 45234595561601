import { LoadingOutlined } from '@ant-design/icons';
import { Page } from '@frontend/app/Page';
import { Spin } from 'antd';
import React from 'react';

import styles from './LoadingPage.module.scss';

export const LoadingPage = () => {
  return (
    <Page>
      <div className={styles.loadingPageContainer}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
      </div>
    </Page>
  )
}