import type { Moment } from 'moment';
import moment from 'moment';
import React, { InputHTMLAttributes, useCallback } from 'react';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { FormInstance } from 'antd';

export const DatePicker = generatePicker<Moment>(momentGenerateConfig);

export interface IUseTypableDatePickerInputProps<T> {
  form: FormInstance<T>;
  regex?: RegExp;
}

export const useTypableDatePickerInput = <T extends any>(props: IUseTypableDatePickerInputProps<T>) => {
  const { form, regex = /\d{4}-\d{2}-\d{2}/ } = props;

  return useCallback((datePickerProps: InputHTMLAttributes<HTMLInputElement>, fieldName: keyof T) => {
    return <input {...datePickerProps} onChange={(e) => {
      if (datePickerProps.onChange) datePickerProps.onChange(e);
  
      const v = e.target.value;
      if (typeof v === 'string' && v.match(regex) && moment(v).isValid()) {
        form.setFieldValue(fieldName.toString(), moment(v));
      }
    }} />
  }, [form, regex]);
}