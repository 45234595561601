// import { NotFoundPage } from '@frontend/app/NotFoundPage';
import { LOGIN_PATH } from '@frontend/app/paths';
import { UserRole } from '@frontend/shared/models/user';
import { message } from 'antd';
import React, { useEffect } from 'react';
import { generatePath, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { useAuthContext } from './authContext';
import { NotFoundPage } from '@frontend/app/NotFoundPage';

interface Props {
  /** When provided only users with this role or the admin role can access the guarded routes */
  roles?: UserRole[];
}

/**
 * This component is meant to be used with nested routes in order
 * to avoid rendering any nested route if auth conditions are not met.
 *
 * This component should only be used in AppRoutes to ensure it only exists
 * once per path
 */
export const RolesRouteGuard: React.FC<Props> = ({ roles }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken, user } = useAuthContext();
  const outlet = useOutlet();

  useEffect(() => {
    if (!accessToken) {
      void message.error('Please login before accessing this URL.');
      navigate(generatePath(LOGIN_PATH), {
        state: {
          ...location,
        },
      })
    }
  }, [accessToken, location]);

  // if no user then render nothing
  if (user && !roles?.includes(user?.role)) {
    return <NotFoundPage />;
  }

  return outlet;
};
