import {
  DownloadOutlined,
  LoadingOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Page } from '@frontend/app/Page';
import { Pane } from '@frontend/components/Pane';
import { Patient, PatientStatus } from '@frontend/shared/models/patient';
import {
  Alert,
  Button,
  Form,
  List,
  Popconfirm,
  message,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { format } from 'date-fns';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { AddNoteButton } from './AddNoteButton/AddNoteButton';
import { IPatientFormFields, PatientForm } from './PatientForm';
import { PatientStatusControl } from './PatientStatusControl/PatientStatusControl';
import { PatientStatusTag } from './PatientStatusTag';
import { generatePatientReportUrl } from './generatePatientReportUrl';
import { PatientConfirmationModal } from './PatientConfirmationModal';
import { PATIENTS_PATH } from '@frontend/app/paths';
import { useDeletePatient, useGetPatient, useUpdatePatient } from '@frontend/api/patients';
import { useAuthContext } from '@frontend/auth/authContext';
import { UserRole } from '@frontend/shared/models/user';
import { NotFoundPage } from '@frontend/app/NotFoundPage';

import styles from './PatientPage.module.scss';

export const PatientPage: React.FC = () => {
  const { user } = useAuthContext();

  const isAdmin = useMemo(() => {
    return user?.role === UserRole.ADMIN;
  }, [user]);

  const navigate = useNavigate();
  const { patientId: matchedPatientId } = useParams();
  const {
    data: patient,
    error,
  } = useGetPatient({ id: matchedPatientId as string });

  const { mutateAsync: updatePatient } = useUpdatePatient();

  const nlrObservation = useMemo(() => {
    return patient?.observations?.find((o) => o.test_name === 'NLR');
  }, [patient]);

  const [form] = Form.useForm<IPatientFormFields>();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const handleFormSubmit = async (): Promise<void> => {
    if (patient) {
      try {
        await form.validateFields();
      } catch (error) {
        // ignore validation errors and rely on inline error messages
        return;
      }

      setIsConfirmModalOpen(true);
    } else {
      void message.error('Patient not loaded');
    }
  };

  const formData = Form.useWatch([], form);

  const onConfirm = useCallback(async () => {
    if (patient) {
      let observations = patient.observations;
      if (observations) {
        const nlrIndex = observations.findIndex((o) => o.test_name === 'NLR');

        if (nlrIndex > -1) {
          if (formData.nlr == null) {
            observations.splice(nlrIndex, 1);
          } else {
            observations[nlrIndex].test_value = formData.nlr.toString();
            observations[nlrIndex].test_date = formData.nlr_test_date?.toISOString();
          }
        } else if (formData.nlr != null) {
          observations.push({
            test_name: 'NLR',
            test_value: formData.nlr.toString(),
            test_units: 'ratio',
            test_date: formData.nlr_test_date?.toISOString(),
          })
        }
      } else {
        observations = formData.nlr ? [
          {
            test_name: 'NLR',
            test_value: formData.nlr.toString(),
            test_units: 'ratio',
            test_date: formData.nlr_test_date?.toISOString(),
          },
        ]
          : []
      }

      try {
        if (matchedPatientId) {
          await updatePatient({
            id: matchedPatientId,
            data: {
              name: formData.name,
              sex: formData.sex,
              histology: formData.histology,
              mrn: formData.mrn,
              radiology_id: formData.radiology_id,
              pd_l1: {
                test_complete: !!formData.pdl1_tps_pct,
                tps: formData.pdl1_tps_pct && formData.pdl1_tps_pct >= 1 ? formData.pdl1_tps_pct / 100.0 : undefined,
              },
              observations,
              date_of_birth: formData.date_of_birth.toISOString(),
              physician_name: formData.physician_name,
            }
          })
          void message.success('Success');
          navigate(generatePath(`${PATIENTS_PATH}`));
        }
      } catch (error) {
        void message.error('Failed to save patient');
      } finally {
        setIsConfirmModalOpen(false);
      }
    } else {
      void message.error('Patient not loaded');
    }
  }, [patient, formData, setIsConfirmModalOpen]);

  const onCancel = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  const initialValues: Partial<IPatientFormFields> = useMemo(() => {
    if (patient) {
      return {
        radiology_id: patient.radiology_id,
        name: patient.name,
        mrn: patient.mrn,
        sex: patient.sex,
        histology: patient.histology,
        date_of_birth: moment(patient.date_of_birth),
        nlr: nlrObservation?.test_value ? parseFloat(nlrObservation.test_value) : undefined,
        nlr_test_date: nlrObservation?.test_date ? moment(nlrObservation.test_date) : undefined,
        pdl1_tps_pct: patient?.pd_l1?.tps != null
          ? (
            patient.pd_l1.tps >= 1
              ? patient.pd_l1.tps
              : patient.pd_l1.tps * 100
          )
          : undefined,
        physician_name: patient.physician_name,
      }
    } else {
      return {};
    }
  }, [patient]);

  const navigateBack = () => {
    navigate(PATIENTS_PATH);
  };

  const { mutateAsync: deletePatient } = useDeletePatient();
  const onDeletePatientConfirmation = useCallback(async () => {
    if (patient) {
      try {
        await deletePatient({
          id: patient.id,
        });
        void message.success('Successfully deleted the patient.')
        navigate(generatePath(PATIENTS_PATH));
      } catch (error) {
        console.error(error);
        void message.error('There was an error deleting the patient.')
      }
    }
  }, [patient]);

  if (error)
  return (
    <Page>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <p>{error.message}</p>
    </Page>
  );

  if (patient === null) {
    return <NotFoundPage />;
  }

  if (!patient?.id)
    return (
      <Page onBack={navigateBack} title={`Patient ${matchedPatientId}`}>
        <LoadingOutlined style={{ fontSize: '32px' }} />
      </Page>
    );

  // normalize and sort notes
  const patientNotes = getNotes(patient);

  return (
    <Page
      onBack={navigateBack}
      title={patient.name ?? 'Patient'}
      subTitle={isAdmin && <PatientStatusTag status={patient.status} />}
      pageActions={(
        <>
          {isAdmin && (
            <>
              <Popconfirm
                title='Delete patient'
                okText='Delete'
                cancelText='Cancel'
                onConfirm={onDeletePatientConfirmation}
                description={(
                  <>
                    <span>Are you sure you want to delete this patient?</span>
                    <br />
                    <span>All their data will be removed completely from our systems.</span>
                  </>
                )}
                okButtonProps={{
                  danger: true,
                }}
              >
                <Button type='primary' danger>
                  Delete Patient
                </Button>
              </Popconfirm>
              <PatientStatusControl
                patient={patient}
              />
            </>
          )}
          {patient.results && patient.results.length > 0 && (
            <a
              href={generatePatientReportUrl(patient)}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '10px' }}
            >
              <Button type="primary">
                <DownloadOutlined /> Download Report
              </Button>
            </a>
          )}
        </>
      )}
    >
      {
        isConfirmModalOpen && (
          <PatientConfirmationModal
            patient={{
              ...formData,
            }}
            initialData={initialValues}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )
      }
      {patient.status === PatientStatus.IMAGING_PENDING && false && (
        <Alert
          style={{ marginBottom: '20px' }}
          message="Patient imaging still needed"
          description="Report cannot be created until patient imaging has been uploaded."
          type="warning"
          action={
            <Button size="small" type="default">
              Learn more
            </Button>
          }
          showIcon
          icon={<PictureOutlined />}
        />
      )}
      {patient.status === PatientStatus.IMAGING_QC_FAIL && (
        <Alert
          style={{ marginBottom: '20px' }}
          message="Patient imaging was not usable"
          description="No scans of the lung were found."
          type="error"
          showIcon
          icon={<PictureOutlined />}
        />
      )}
      <Pane>
        <PatientForm
          form={form}
          handleFormSubmit={handleFormSubmit}
          initialValues={initialValues}
          handleGoBack={() => navigateBack()}
        />
      </Pane>
      {isAdmin && (
        <Pane className={styles.historyPane}>
          <PageHeader
            title='History'
            className={styles.historyHeader}
            extra={<AddNoteButton patientId={patient.id} />}
          />
          <List
            dataSource={patientNotes}
            rootClassName={styles.list}
            renderItem={(note) => (
              <List.Item>
                <List.Item.Meta
                  title={(
                    <span>{`${format(new Date(note.date ?? Date.now()), 'yyyy-MM-dd')} by `}
                    <i>{`${note.user?.user_name ?? '-'}`}</i>
                    :
                    </span>
                  )}
                  description={note.note}
                />
              </List.Item>
            )}
          />
        </Pane>
      )}
    </Page>
  );
};

const getNotes = (patient: Patient) => {
  return reverse(
    sortBy(patient.notes, (note) =>
      note?.date ? new Date(note.date).getTime() : Date.now(),
    ),
  );
};
