import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import * as React from 'react';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { AuthProvider } from '@frontend/auth/authContext';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@frontend/api/client';
import { Colors } from './GlobalStyles';

export const App: FC = () => (
  <ConfigProvider
    theme={{
      components: {
        Layout: {
          colorBgHeader: Colors.white,
          colorBgBody: Colors['light-grey']
        }
      }
    }}
  >
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          {/* <GlobalStyles /> */}
          <AppRoutes />
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  </ConfigProvider>
);
